<template>
  <div class="container">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h5>
                        الطلبات
                    </h5>
                </div>
                <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-4 g">
                        <span>البحث</span>
                        <input type="text" placeholder="ابحث هنا..." class="form-control" v-model="q" @keyup="page = 0; gett();">
                    </div>
                    <div class="col-12 col-lg-4 g">
                        <div class="form-group">
                          <span for="">الحالة</span>
                          <select class="form-control" v-model="status" @change="page = 0; gett();">
                            <option value="all">الكل</option>
                            <option :value="1">تم بنجاح</option>
                            <option :value="2">فشل</option>
                            <option :value="0">انتظار</option>
                          </select>
                        </div>
                    </div>
                </div>
                <img :src="require('@/assets/images/loading.svg')" alt="" width="50" v-if="loading">
                    <div class="col-12 table-responsive">
                        <table class="table table-hover table-bordered table-sm">
                            <thead>
                                <th>
                                    ID
                                </th>
                                <th>
                                    العميل
                                </th>
                                <th>
                                    الخدمة
                                </th>
                                <th>
                                    التاريخ
                                </th>
                                <th>
                                    الحالة
                                </th>
                                <th>
                                    الاجمالي
                                </th>
                                <th>
                                    يدوي
                                </th>
                                <th>
                                    التفاصيل 
                                </th>
                            </thead>
                            <tbody>
                                <tr v-for="order in orders" :key="order._id">
                                    <td>
                                        {{ order.order_id }}
                                    </td>
                                    <td>
                                        {{ order.user.name }}
                                    </td>
                                    <td>
                                        <span v-if="order.service">
                                            {{ order.service.title }}
                                        </span>
                                    </td>
                                    <td>
                                        {{ order.date }}
                                    </td>
                                    <td>
                                        <button v-if="order.status == 1" class="btn btn-sm btn-success" style="border-radius: 0px">
                                            <i class="fa fa-check"></i>
                                            تم بنجاح
                                        </button>
                                        <button v-if="order.status == 2" class="btn btn-danger btn-sm" style="border-radius: 0px">
                                            <i class="fa fa-times"></i>
                                            فشل
                                        </button>
                                        <button v-if="order.status == 0" class="btn btn-warning btn-sm" style="border-radius: 0px">
                                            <i class="fa fa-clock-o"></i>
                                            في الانتظار
                                        </button>
                                        <button class="btn btn-sm" style="border-radius: 0px; padding: 0px">
                                            <img :src="require('@/assets/images/sada.png')" style="width: 20px" v-if="order.sada">
                                        </button>
                                        <template v-if="order.order_info">
                                            <template v-if="order.order_info.type">
                                                <template v-if="order.order_info.type == 'update'">
                                                    <button class="btn btn-sm" style="border-radius: 0px; padding: 0px">
                                                        <img :src="require('@/assets/images/update-count.png')" style="width: 20px">
                                                    </button>
                                                </template>
                                            </template>
                                        </template>
                                    </td>
                                    <td>
                                        {{ order.total }}
                                    </td>
                                    <td>
                                        <span v-if="order.manual" class="text-danger">يدوي</span>
                                        <span v-if="!order.manual" class="text-success">اونلاين</span>
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-primary" @click="current = order;" v-b-modal.details>
                                            التفاصيل
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12">
                        <button class="btn btn-danger" @click="page--; gett()" v-if=" page > 0 && !loading">الصفحة السابقة <i class="fa fa-arrow-right"></i></button>&nbsp;
                        <button class="btn btn-primary" @click="page++; gett()" v-if="orders.length > 98 && !loading">الصفحة التالية <i class="fa fa-arrow-left"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="details" title="التفاصيل" hide-footer>
        <div class="col-12 table-responsive" v-if="current._id">
            <table class="table table-hover table-bordered table-sm">
                <tbody>
                    <tr>
                        <td>
                            الحالة
                        </td>
                        <td>
                            <select v-model="current.status">
                                <option :value="0">في الانتظار</option>
                                <option :value="1">تم بنجاح</option>
                                <option :value="2">فشل</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            نوع الدفع
                        </td>
                        <td>
                            <select v-model="current.manual">
                                <option :value="false">اونلاين</option>
                                <option :value="true">يدوي</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            يدوي
                        </td>
                        <td>
                            {{ current.manual_notes  }}
                            <br>
                            <a :href="current.manual_file" target="_blank" v-if="current.manual_file && current.manual_file != ''">عرض الملف </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            الدفع على صدى؟
                        </td>
                        <td>
                            <select v-model="current.sada">
                                <option :value="false">لا</option>
                                <option :value="true">نعم على صدى</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            المبلغ
                            <br>
                            <small>
                                سيتم اعادة احتساب الضريبة ونسبة المسوق.
                            </small>
                        </td>
                        <td>
                            <input type="number" class="form-control form-control-lg" v-model="current.original">
                        </td>
                    </tr>
                    
                    <tr v-if="current.order_info.request_students_count">
                        <td>
                            عدد الطلاب
                        </td>
                        <td>
                            {{current.order_info.students}}
                        </td>
                    </tr>
                    <tr v-if="current.request_teachers_count">
                        <td>
                            عدد المعلمين
                        </td>
                        <td>
                            {{current.order_info.teachers}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="col-12 text-center g">
                <button class="btn btn-success" @click="save()">
                    حفظ التعديلات
                </button>
            </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data(){
    return {
      jwt: localStorage.getItem("jwt"),
      orders: [],
      loaded: false,
      current: {},
      q: "",
      page: 0,
      status: "all"
    }
  },
  created(){
    var g = this;
    g.gett()
  },
  methods: {
    gett(){
      var g = this;
      $.post(api + '/admin/orders', {
        jwt: this.jwt,
        q: this.q,
        page: this.page,
        status: this.status
      }).then(function(a){
        g.orders = a.response
      })
    },
    save(){
        var g = this;
        $.post(api + '/admin/orders-edit', {
            jwt: this.jwt,
            _id: g.current._id,
            status: g.current.status,
            original: g.current.original,
            manual: g.current.manual,
            sada: g.current.sada
        }).then(function(a){
            g.gett()
            alert("تم بنجاح", 100)
        }) 
    }
  }
}
</script>
